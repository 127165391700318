import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setAlert } from './alertSlice';
import { handleApiError } from '../utils/errorHandler';

// Async thunk for uploading ASN data
export const asnUpload = createAsyncThunk(
  'asn/upload',
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post('/v1/api/asn/upload', formData);
      dispatch(setAlert({ msg: 'File uploaded successfully!', alertType: 'success' }));
      return response.data;
    } catch (error) {
      const errorMessage = handleApiError(error);
      dispatch(setAlert({ msg: errorMessage, alertType: 'danger' }));
      return rejectWithValue(errorMessage);
    
    }
  }
);


export const fetchAsnData = createAsyncThunk(
  'fetchAsnData/fetchAsn',
  async ({ page, search }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`/v1/api/asn/poDetails?page=${page}&search=${search}`);
      return response.data;
    } catch (error) {
      const errorMessage = handleApiError(error);
      dispatch(setAlert({ msg: errorMessage, alertType: 'danger' }));
      return rejectWithValue(errorMessage);
    }
  }
);



export const latestUploadedByAsn = createAsyncThunk(
  "asn/latestUploadedByAsn",
  async (_, thunkAPI) => {
    try {
      const response = await fetch("/v1/api/asn/latestUpload");
      if (!response.ok) {
        throw new Error("Failed to fetch latest upload data");
      }
      return await response.json();
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);



const asnSlice = createSlice({
  name: 'asnUpload',
  initialState: {
    asnUploadData: [],
    asnFetchData: [],
    latestUploadByAsnData: null,
    loading: {
      upload: false,
      fetch: false,
      latestUpload: false,
    },
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asnUpload.pending, (state) => {
        state.loading.upload = true;
        state.error = null;
      })
      .addCase(asnUpload.fulfilled, (state, action) => {
        state.loading.upload = false;
        state.asnUploadData = Array.isArray(action.payload) ? [...state.asnUploadData, ...action.payload] : [];
      })
      .addCase(asnUpload.rejected, (state, action) => {
        state.loading.upload = false;
        state.error = action.payload || action.error.message;
      })

      .addCase(fetchAsnData.pending, (state) => {
        state.loading.fetch = true;
        state.error = null;
      })
      .addCase(fetchAsnData.fulfilled, (state, action) => {
        state.loading.fetch = false;
        state.asnFetchData = action.payload;
        // state.asnFetchData = action.payload.results ? [...action.payload.results].reverse() : [];
      })
      .addCase(fetchAsnData.rejected, (state, action) => {
        state.loading.fetch = false;
        state.error = action.payload || action.error.message;
      })

      .addCase(latestUploadedByAsn.pending, (state) => {
        state.loading.latestUpload = true;
      })
      .addCase(latestUploadedByAsn.fulfilled, (state, action) => {
        state.loading.latestUpload = false;
        state.latestUploadByAsnData = action.payload;
      })
      .addCase(latestUploadedByAsn.rejected, (state, action) => {
        state.loading.latestUpload = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default asnSlice.reducer;





