import { configureStore } from "@reduxjs/toolkit";
import alertReducer from '../features/alertSlice';
import asnUploadReducer from "../features/asnSlice";
import grnUploadReducer from "../features/grnSlice";
import trackingStatusReducer from '../features/trackingStatusSlice';
import monthlyPoReducer from '../features/monthlyPoSlice';
import authReducer from '../features/authSlice';
import UserAccessReducer from '../features/UserAccessSlice';
import logger from "redux-logger";





export const store = configureStore({
  reducer: {
    alerts: alertReducer,
    asnUpload: asnUploadReducer,
    grnUpload: grnUploadReducer,
    trackingStatus: trackingStatusReducer,
    monthlyPo: monthlyPoReducer,
    auth: authReducer,
    UserAccess: UserAccessReducer,

  },


  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(logger),

  // devTools: process.env.NODE_ENV !== "production",
});

