// import React, { useState } from "react";
// import logo from "../assets/emiratesLogo.png";
// import { NavLink } from "react-router-dom";
// import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faMapMarkerAlt,
//   faInfoCircle,
//   faCommentAlt,
//   faSignInAlt,
// } from "@fortawesome/free-solid-svg-icons";
// import "./Navbar.css"; 

// const HeaderNavbar = () => {
//   const [hoveredIndex, setHoveredIndex] = useState(null);
//   const [clickedIndex, setClickedIndex] = useState(null);

//   const navLinkStyle = (index) => ({
//     color: hoveredIndex === index ? "#0056b3" : "#003c78", // Hover color
//     textDecoration: "none",
//     fontFamily: "'Montserrat', sans-serif",
//     fontSize: "small",
//     transition: "color 0.3s ease, transform 0.2s ease",
//     transform:
//       clickedIndex === index ? "scale(0.95)" : hoveredIndex === index ? "scale(1.05)" : "scale(1)",
//   });

//   return (
//     <Navbar expand="lg" style={{ backgroundColor: "transparent", height: "70px" }}>
//       <Container fluid className="d-flex justify-content-between">
//         <NavLink to="/">
//           <img
//             src={logo}
//             alt="logo"
//             style={{
//               height: "90px",
//               objectFit: "cover",
//               objectPosition: "center top",
//               overflow: "hidden",
//             }}
//           />
//         </NavLink>
//         <Navbar.Toggle aria-controls="navbarScroll" />
//         <Navbar.Collapse
//           id="navbarScroll"
//           className="navbar-collapse-custom" // Add a class
//         >
//           <Nav
//             className="ms-auto my-2 my-lg-0"
//             style={{
//               maxHeight: "100%",
//               fontFamily: "'Montserrat', sans-serif",
//               fontSize: "small",
//                paddingLeft: "20px", display: "flex", alignItems: "center"
//             }}
//             navbarScroll
//           >
//             <NavLink
//               to="/"
//               style={{
//                 color: "#003c78",
//                 textDecoration: "none",
//                 fontFamily: "'Montserrat', sans-serif",
//                 fontSize: "small",
//               }}
//               className="nav-link"
//             >
//               <FontAwesomeIcon
//                 icon={faMapMarkerAlt}
//                 style={{ marginRight: "2px" }}
//               />{" "}
//               Track
//             </NavLink>
//             <NavLink
//               to="http://www.emirateslogistics.com/"
//               target="_blank"
//               style={{
//                 color: "#003c78",
//                 textDecoration: "none",
//                 fontFamily: "'Montserrat', sans-serif",
//                 fontSize: "small",
//               }}
//               className="nav-link"
//             >
//               <FontAwesomeIcon
//                 icon={faInfoCircle}
//                 style={{ marginRight: "2px" }}
//               />{" "}
//               About
//             </NavLink>
//             <NavLink
//               to="/feedback"
//               style={{
//                 color: "#003c78",
//                 textDecoration: "none",
//                 fontFamily: "'Montserrat', sans-serif",
//                 fontSize: "small",
//               }}
//               className="nav-link"
//             >
//               <FontAwesomeIcon
//                 icon={faCommentAlt}
//                 style={{ marginRight: "2px" }}
//               />{" "}
//               Feedback
//             </NavLink>
//             <NavLink
//               to="/login"
//               style={{
//                 color: "#003c78",
//                 textDecoration: "none",
//                 fontFamily: "'Montserrat', sans-serif",
//                 fontSize: "small",
//               }}
//               className="nav-link"
//             >
//               <FontAwesomeIcon
//                 icon={faSignInAlt}
//                 style={{ marginRight: "2px" }}
//               />{" "}
//               Login
//             </NavLink>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// };

// export default HeaderNavbar;


import React, { useState } from "react";
import logo from "../assets/emiratesLogo.png";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faInfoCircle,
  faCommentAlt,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./Navbar.css"; 

const HeaderNavbar = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [clickedIndex, setClickedIndex] = useState(null);

  const navLinkStyle = (index) => ({
    color: hoveredIndex === index ? "#0056b3" : "#003c78",
    textDecoration: "none",
    marginRight:"10px",
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "small",
    transition: "color 0.3s ease, transform 0.3s ease",
    transform:
      clickedIndex === index ? "scale(0.95)" : hoveredIndex === index ? "scale(1.1)" : "scale(1)",
  });

  return (
    <Navbar expand="lg" style={{ backgroundColor: "#ffffff60", minHeight:"100%"}}>
      <Container fluid className="d-flex justify-content-between">
        <NavLink to="/">
          <img
            src={logo}
            alt="logo"
            style={{
              height: "90px",
              objectFit: "cover",
              objectPosition: "center top",
              overflow: "hidden",
              margin:"-20px 0px"
            }}
          />
        </NavLink>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="navbar-collapse-custom">
          <Nav
            className="ms-auto my-2 my-lg-0"
            style={{
              maxHeight: "100%",
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "small",
              paddingLeft: "20px",
              display: "flex",
              alignItems: "center",
            }}
            navbarScroll
          >
            {[
              { to: "/", text: "Track", icon: faMapMarkerAlt },
              { to: "http://www.emirateslogistics.com/", text: "About", icon: faInfoCircle, external: true },
              { to: "/feedback", text: "Feedback", icon: faCommentAlt },
              { to: "/SignUp4", text: "Sign-up", icon: faCommentAlt },
              { to: "/login", text: "Login", icon: faSignInAlt },
            ].map((item, index) => (
              <NavLink
                to={item.to}
                key={index}
                style={navLinkStyle(index)}
                className="nav-link"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                onClick={() => setClickedIndex(index)}
                target={item.external ? "_blank" : undefined}
              >
                <FontAwesomeIcon icon={item.icon} style={{ marginRight: "2px" }} /> {item.text}
              </NavLink>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderNavbar;

