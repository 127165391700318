import { Routes, Route } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import "./App.css";
import Alert from "./component/Alert";
import Loading from "./component/Loading/Loading";
import Auth from "./utils/auth"; // Corrected import to match component naming
import Feedback from "./Pages/Feedback/Feedback";
import SignUp from "./auth/SignUp/SignUp";
import MonthlyPO from "./Pages/Monthly_PO/MonthlyPO";
import SignUp2 from "./auth/SignUp/SignUp2";
import ForgetPassword from "./auth/ForgetPassword/ForgetPassword";
import VendorSignUp from "./auth/SignUp/VendorSignUp";
import setAuthToken from "./utils/setAuthToken"
import UserAccess from "./Pages/UserAccess/UserAccess";

// Lazy load components
const Login = lazy(() => import("./auth/Login"));
const Tracking = lazy(() => import("./Pages/Tracking/Tracking"));
const TrackingStatus = lazy(() =>
  import("./Pages/TrackingStatus/TrackingStatus")
);
const Upload = lazy(() => import("./Pages/Upload/Upload"));
const AsnsTable = lazy(() => import("./Pages/Upload/Asns/AsnsTable"));
const GrnTable = lazy(() => import("./Pages/Upload/Grn/GrnTable"));
const POdetails = lazy(() => import("./Pages/POdetails/POdetails"));

// const CollectionSummaryTable = lazy(() =>
//   import("./Pages/MISCollection/CollectionSummary/CollectionSummaryTable")
// );
// const DispatchSummaryTable = lazy(() =>
//   import("./Pages/MISDispatch/DispatchSummary/DispatchSummaryTable")
// );
// const DiagonalCollectionTable = lazy(() =>
//   import("./Pages/MISCollection/DiagonalCollection/DiagonalCollectionTable")
// );
// const DiagonalDispatchTable = lazy(() =>
//   import("./Pages/MISDispatch/DiagonalDispatch/DiagonalDispatchTable")
// );

const PlanSuggestion = lazy(() =>
  import("./Pages/PlanSuggestion/PlanSuggestion")
);

function App() {

  const token = sessionStorage.getItem('token')

  if(token){
    setAuthToken(token)
  }


  return (
    <>
      <Alert />
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/SignUp" element={<SignUp/>} />
          <Route path="/SignUp2" element={<SignUp2/>} />
          <Route path="/SignUp4" element={<VendorSignUp/>} />
          <Route path="/forgetPassword" element={<ForgetPassword/>} />
          <Route path="/" element={<Tracking />} />
          <Route path="/trackingStatus/:poDoc" element={<TrackingStatus />} />
          <Route path="/POdetails" element={<POdetails />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/monthlyPO" element={<MonthlyPO/>} />
          


          {/* Protected Routes */}
          <Route
            path="/Upload"
            element={
              <Auth>
                <Upload />
              </Auth>
            }
          />
          <Route
            path="/AsnsTable"
            element={
              <Auth>
                <AsnsTable />
              </Auth>
            }
          />
          <Route
            path="/GrnTable"
            element={
              <Auth>
                <GrnTable />
              </Auth>
            }
          />

          {/* latter on use when client need MIS PART */}
          {/* <Route
            path="/CollectionSummaryTable"
            element={
              <Auth>
                <CollectionSummaryTable />
              </Auth>
            }
          />
          <Route
            path="/DispatchSummaryTable"
            element={
              <Auth>
                <DispatchSummaryTable />
              </Auth>
            }
          />
          <Route
            path="/DiagonalCollectionTable"
            element={
              <Auth>
                <DiagonalCollectionTable />
              </Auth>
            }
          />
          <Route
            path="/DiagonalDispatchTable"
            element={
              <Auth>
                <DiagonalDispatchTable />
              </Auth>
            }
          /> */}
          <Route
            path="/PlanSuggestion"
            element={
              <Auth>
                <PlanSuggestion />
              </Auth>
            }
          />
            <Route
            path="/UserAccess"
            element={
              <Auth>
                <UserAccess />
              </Auth>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
