import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  Table,
  Pagination,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  AiOutlineSearch,
  AiFillCheckCircle,
  AiFillCloseCircle,
} from "react-icons/ai";

import { useDispatch, useSelector } from "react-redux";
import NavbarAdmin from "../../component/NavbarAdmin";
import { fetchUserAccessData } from "../../features/UserAccessSlice";
import axios from "axios";

const columns = [
  { id: "userId", label: "User ID", minWidth: 130 },
  { id: "fullName", label: "Full Name", minWidth: 200 },
  { id: "email", label: "Email", minWidth: 180 },
  // { id: "utmId", label: "UTM ID", minWidth: 130 },
  // { id: "userTypeId", label: "User Type ID", minWidth: 130 },
  // { id: "vendorCode", label: "Vendor Code", minWidth: 130 },
  { id: "phoneNumber", label: "Phone Number", minWidth: 150 },
  { id: "userTypeMain", label: "User Type Main", minWidth: 150 },
  { id: "category", label: "Category", minWidth: 150 },
  { id: "vendorName", label: "Vendor Name", minWidth: 150 },
  { id: "userType", label: "user Type", minWidth: 150 },
  { id: "status", label: "Status", minWidth: 130 },
];

console.log(fetchUserAccessData, "accesss");

const UserAccess = () => {
  const dispatch = useDispatch();
  const {
    data: userAccessFetchData,
    loading,
    error,
  } = useSelector((state) => state.UserAccess);

  console.log(userAccessFetchData, "userdata");

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(fetchUserAccessData({ page, search }));
  }, [dispatch, page, search]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const toggleStatus = async (userId, currentStatus) => {
    try {
      const newStatus = currentStatus === "Active" ? "Inactive" : "Active";
      await axios.patch(`/v1/api/user/status/${userId}`, { status: newStatus });
      dispatch(fetchUserAccessData({ page, search })); // Refresh data
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };



  const toggleUsertype = async (userId, currentUsertype) => {
    try {
      console.log("UserId:", userId, "Current UserType:", currentUsertype);
      const newUserType = currentUsertype === "General" ? "Admin" : "General";
      await axios.patch(
        `/v1/api/user/updateType/${userId}`,
        { Usertype: newUserType },
        { headers: { "Content-Type": "application/json" } }
      );
      dispatch(fetchUserAccessData({ page, search })); // Refresh data
    } catch (error) {
      console.error("Error updating user type:", error.response?.data || error.message);
    }
  };
  

  return (
    <>
      <Container fluid className="p-0">
        <NavbarAdmin />
        <Row className="mt-2 mx-2" style={headerStyle}>
          <Col className="d-flex align-items-center">
            <h3 className="mb-0" style={headerTextStyle}>
              User Access
            </h3>
          </Col>
        </Row>
        <Container fluid className="p-3 pb-0">
          <Row className="justify-content-start mb-2">
            <Col xs={10} sm={8} md={6} lg={4}>
              <Form className="d-flex">
                <Form.Control
                  type="search"
                  placeholder="Search by User Name"
                  className="me-2"
                  value={search}
                  onChange={handleSearchChange}
                  style={{ fontSize: "13px" }}
                />
                <Button
                  variant="outline-warning"
                  style={{ backgroundColor: "#f90", borderColor: "#f90" }}
                  onClick={() => setPage(1)}
                >
                  <AiOutlineSearch style={{ color: "white" }} />
                </Button>
              </Form>
            </Col>
          </Row>

          <div
            style={{
              maxHeight: "calc(100vh - 255px)",
              minHeight: "calc(100vh - 255px)",
              maxWidth: "100%",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              scrollbarWidth: "thin",
              scrollbarColor: "#003c78 transparent",
            }}
          >
            <Table responsive bordered hover>
              <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "700",
                        textAlign: "center",
                        background: "#003c78",
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {userAccessFetchData &&
                userAccessFetchData.data &&
                userAccessFetchData.data.length > 0 ? (
                  userAccessFetchData.data.map((row, index) => (
                    <tr
                      key={index}
                      style={{
                        opacity: row.status === "Inactive" ? 0.5 : 1,
                      }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <td
                            key={column.id}
                            style={{
                              fontSize: "12px",
                              textAlign: "center",
                              pointerEvents:
                                column.id === "status" ||
                                column.id === "userType"
                                  ? "auto"
                                  : "none",
                            }}
                          >
                            {column.id === "status" ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="status-tooltip">
                                    {value === "Active"
                                      ? "Click to deactivate the user"
                                      : "Click to activate the user"}
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant={
                                    value === "Active" ? "success" : "danger"
                                  }
                                  size="sm"
                                  onClick={() =>
                                    toggleStatus(row.userId, value)
                                  }
                                  style={{ fontSize: "11px" }}
                                >
                                  {value === "Active" ? (
                                    <AiFillCheckCircle
                                      size={13}
                                      className="me-1"
                                    />
                                  ) : (
                                    <AiFillCloseCircle
                                      size={13}
                                      className="me-1"
                                    />
                                  )}
                                  {value}
                                </Button>
                              </OverlayTrigger>
                            ) : column.id === "userType" ? (
                              <Button
                                 size="sm"
                                onClick={() => toggleUsertype(row.userId, value)}
                                disabled={row.status === "Inactive"}
                                style={{ fontSize: "11px", background:"grey" }}
                              >
                                {value}
                              </Button>
                            ) : (
                              value
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={columns.length}
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      No data found in the specified condition.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          {loading.fetch && <span>Loading...</span>}
          {error && <span style={{ color: "red" }}>{error}</span>}

          <Pagination className="justify-content-end mt-2">
            <Pagination.Prev
              onClick={() => handleChangePage(page > 1 ? page - 1 : 1)}
            />
            <Pagination.Item>{page}</Pagination.Item>
            <Pagination.Next onClick={() => handleChangePage(page + 1)} />
          </Pagination>
        </Container>
      </Container>
    </>
  );
};

export default UserAccess;

// Styles
const headerStyle = {
  height: "50px",
  backgroundColor: "#e9ecef",
  borderBottom: "2px solid #ced4da",
  padding: "5px",
};

const headerTextStyle = {
  fontSize: "14px",
  textTransform: "uppercase",
  fontWeight: "600",
  color: "#003c78",
};
