// import axios from "axios";

// const setAuthToken = (token) => {

//   console.log(token);
  
//   if (token) {
//     axios.defaults.headers.common["Authorization"] = token;
//   } else {
//     delete axios.defaults.headers.common["Authorization"];
//   }
// };

// export default setAuthToken;





import axios from "axios";

const setAuthToken = (token) => {
  if (token) {
    // Format token as Bearer token
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    // Remove the Authorization header when no token is provided
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
