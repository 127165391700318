import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setAlert } from './alertSlice';
import { handleApiError } from '../utils/errorHandler';

// Async thunk for fetching monthly PO data
export const fetchMonthlyPoData = createAsyncThunk(
  'monthlyPoData/fetch',
  async ({ page, search }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `v1/api/po/poDetailsMonthlyDis?page=${page}&search=${search}`
      );
      return response.data; // Assuming the API response is directly the data
    } catch (error) {
      const errorMessage = handleApiError(error);
      dispatch(setAlert({ msg: errorMessage, alertType: 'danger' }));
      return rejectWithValue(errorMessage);
    }
  }
);

// Slice definition
const monthlyPoSlice = createSlice({
  name: 'monthlyPoData',
  initialState: {
    data: [], // Holds fetched PO data
    latestUploadByAsnData: null, // Placeholder for additional data
    loading: false, // Tracks loading state
    error: null, // Tracks errors
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMonthlyPoData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMonthlyPoData.fulfilled, (state, action) => {
        state.loading = false;
        // Assuming the API response has a 'results' key
        state.data = action.payload.results || action.payload; // Store the data
      })
      .addCase(fetchMonthlyPoData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default monthlyPoSlice.reducer;
