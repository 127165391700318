// UserAccessSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Fetch User Access Data
export const fetchUserAccessData = createAsyncThunk(
  'UserAccess/fetchUserAccessData',
  async ({ page, search }) => {
    try {
      const response = await axios.get('/v1/api/user/allDetails', {
        params: { page, search },
      });
      return response.data;
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;
      throw new Error(errorMessage);
    }
  }
);

const UserAccessSlice = createSlice({
    name: "UserAccess",
    initialState: {
      data: [],
      loading: {
        fetch: false,
      },
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchUserAccessData.pending, (state) => {
          state.loading.fetch = true;
          state.error = null;
        })
        .addCase(fetchUserAccessData.fulfilled, (state, action) => {
          state.loading.fetch = false;
          state.data = action.payload; // Ensure the payload matches the API response.
        })
        .addCase(fetchUserAccessData.rejected, (state, action) => {
          state.loading.fetch = false;
          state.error = action.error.message || "Something went wrong";
        });
    },
  });
  
  export default UserAccessSlice.reducer;
  