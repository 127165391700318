// utils/errorHandler.js
export const handleApiError = (error) => {
    if (error.response) {
      // Server responded with a status code
      const status = error.response.status;
      const message = error.response.data.message || error.message;
  
      switch (status) {
        case 400:
          return 'Bad request. Please check the input data.';
        case 401:
          return 'Unauthorized. Please log in again.';
        case 404:
          return 'Resource not found.';
        case 500:
          return 'Internal server error. Please try again later.';
        default:
          return message || 'An unexpected server error occurred.';
      }
    } else if (error.request) {
      // Request made but no response received
      return 'No response from the server. Please check your network connection.';
    } else {
      // Something happened in setting up the request
      return error.message || 'An unexpected error occurred.';
    }
  };
  