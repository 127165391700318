import React from "react";
import { Container, Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import logo from "../assets/emiratesLogo.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faMapMarkerAlt,
  faCommentDots,
  faCalendar,
  faSignOutAlt,
  faList,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";

const NavbarAdmin = () => {
  const navigate = useNavigate(); // Initialize navigate

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) { // Optional confirmation
      console.log("Logging out...");
      sessionStorage.removeItem("token"); // Clear session storage
      navigate("/login"); // Redirect to the login page
    }
  };

  return (
    <div>
      <Navbar expand="lg" className="p-1" style={{ backgroundColor: "transparent", height: "70px" }}>
        <Container fluid className="d-flex p-0">
          <Link to="/upload">
            <img
              src={logo}
              alt="logo"
              style={{
                height: "90px",
                objectFit: "cover",
                objectPosition: "center top",
                overflow: "hidden",
              }}
            />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ background: "white" }} />
          <Navbar.Collapse id="responsive-navbar-nav" className="navbar-collapse-custom">
            <Nav className="me-auto" style={{ paddingLeft: "20px", display: "flex", alignItems: "center" }}>
              <Nav.Link href="#home" style={{ color: "#003c78", fontSize: "14px" }}>
                <FontAwesomeIcon icon={faChartLine} style={{ marginRight: "5px" }} />
                Dashboard
              </Nav.Link>

              <NavDropdown
                title={
                  <span style={{ color: "#003c78", fontSize: "14px", cursor: "pointer" }}>
                    <FontAwesomeIcon icon={faClipboardList} style={{ marginRight: "5px" }} />
                    MIS Collection
                  </span>
                }
                id="mis-dropdown"
              >
                <NavDropdown.Item as={Link} to="/CollectionSummaryTable" style={{ color: "#04648d" }}>
                  Collection Summary
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/DiagonalCollectionTable" style={{ color: "#04648d" }}>
                  Diagonal Collection
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title={
                  <span style={{ color: "#003c78", fontSize: "14px", cursor: "pointer" }}>
                    <FontAwesomeIcon icon={faClipboardList} style={{ marginRight: "5px" }} />
                    MIS Dispatch
                  </span>
                }
                id="misDispatch-dropdown"
              >
                <NavDropdown.Item as={Link} to="/DispatchSummaryTable" style={{ color: "#04648d" }}>
                  Dispatch Summary
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/DiagonalDispatchTable" style={{ color: "#04648d" }}>
                  Diagonal Dispatch
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href="#link" style={{ color: "#003c78", fontSize: "14px" }}>
                <FontAwesomeIcon icon={faList} style={{ marginRight: "5px" }} />
                PO
              </Nav.Link>

              <Nav.Link as={Link} to="/" style={{ color: "#003c78", fontSize: "14px" }}>
                <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: "5px" }} />
                Track
              </Nav.Link>

              <Nav.Link as={Link} to="/feedback" style={{ color: "#003c78", fontSize: "14px" }}>
                <FontAwesomeIcon icon={faCommentDots} style={{ marginRight: "5px" }} />
                Feedback
              </Nav.Link>
              <Nav.Link as={Link} to="/monthlyPO" style={{ color: "#003c78", fontSize: "14px" }}>
                <FontAwesomeIcon icon={faCalendar} style={{ marginRight: "5px" }} />
                Monthly PO
              </Nav.Link>
              <Nav.Link as={Link} to="/UserAccess" style={{ color: "#003c78", fontSize: "14px" }}>
                <FontAwesomeIcon icon={faCalendar} style={{ marginRight: "5px" }} />
                User Access
              </Nav.Link>
            </Nav>
            <Nav style={{ padding: "0px 20px" }}>
              <Nav.Link as={Link} to="/login" style={{ color: "#003c78", fontSize: "14px" }}>
              <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: "5px" }} />
              Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavbarAdmin;
