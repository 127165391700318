// import React, { useEffect, useState } from "react";
// import { Form, Button, Container, Row, Col } from "react-bootstrap";
// import HeaderNavbar from "../../component/HeaderNavbar";
// import { Link, useNavigate } from "react-router-dom";
// import "./SignUp.css";
// import logo from "../../assets/el_logo.png";
// import axios from "axios";

// const SignUp = () => {
//   const [data, setData] = useState({
//     vendorCode: "",
//     enteredCaptcha: "",
//   });

//   const [captcha, setCaptcha] = useState("");
//   const [isHovered, setIsHovered] = useState(false);
//   const [vendorData, setVendorData] = useState([]);
//   const [validatedUser, setValidatedUser] = useState(null);
//   const navigate = useNavigate();

//   // Fetch Vendor Codes on Component Mount
//   useEffect(() => {
//     const fetchVendorCode = async () => {
//       try {
//         const res = await axios.get("/v1/api/vendor/get");
//         setVendorData(res.data);
//       } catch (error) {
//         console.error("Error fetching vendor codes:", error);
//       }
//     };

//     fetchVendorCode();
//   }, []); // Empty dependency array ensures it runs only once

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setData((prevData) => ({
//       ...prevData,
//       [name]: value.toUpperCase(),
//     }));
//   };

//   const handleVendorCodeSubmit = (e) => {
//     e.preventDefault();
//     const user = vendorData.find((u) => u.vendorCode === data.vendorCode);
//     if (user) {
//       // Generate a random 4-digit CAPTCHA
//       const randomCaptcha = Math.floor(1000 + Math.random() * 9000).toString();
//       setCaptcha(randomCaptcha);
//       setValidatedUser(user);
//     } else {
//       alert("Invalid Vendor Code. Please try again.");
//     }
//   };

//   const handleCaptchaSubmit = (e) => {
//     e.preventDefault();
//     if (data.enteredCaptcha === captcha) {
//       navigate("/SignUp2",{
//         state: {
//           vendorCode: validatedUser.vendorCode,
//           vendorName: validatedUser.vendorName,
//         },
//       });
//     } else {
//       alert("Incorrect CAPTCHA. Please try again.");
//     }
//   };

//   return (
//     <div className="SignUp-main">
//       <HeaderNavbar />
//       <Container
//         className="d-flex align-items-center justify-content-end flex-row"
//         style={{ width: "100%", height: "calc(100vh - 150px)" }}
//       >
//         <Row
//           className="w-100 py-4 px-2 m-2"
//           style={{
//             maxWidth: "400px",
//             backgroundColor: "rgba(0, 0, 0, 0.9)",
//             borderRadius: "8px",
//           }}
//         >
//           <Col>
//             <div className="text-center mb-4">
//               <img src={logo} alt="OWM Logo" style={{ width: "65px" }} />
//               <hr style={{ color: "white", margin: "10px 0px" }} />
//               <h3 style={{ color: "white" }}>Sign Up</h3>
//             </div>

//             {/* Vendor Code Form */}
//             <Form onSubmit={captcha ? handleCaptchaSubmit : handleVendorCodeSubmit} className="SignUp-form p-2 rounded">
//               <Form.Group className="mb-3" controlId="formBasicVendorCode">
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter Vendor Code"
//                   name="vendorCode"
//                   value={data.vendorCode}
//                   onChange={handleChange}
//                   className="transparent-input"
//                   aria-label="Enter your Vendor Code"
//                   disabled={!!captcha}
//                 />
//               </Form.Group>

//               {/* CAPTCHA Section */}
//               {captcha && (
//                 <>
//                   <Form.Group className="mb-3">
//                     <Form.Control
//                       type="text"
//                       placeholder="Enter CAPTCHA"
//                       className="transparent-input"
//                       name="enteredCaptcha"
//                       value={data.enteredCaptcha}
//                       onChange={handleChange}
//                       aria-label="Enter CAPTCHA"
//                     />
//                   </Form.Group>
//                   <p style={{ color: "black", fontSize: "16px", textAlign: "center", background: "#e7f0fe" }}>
//                     CAPTCHA: <strong>{captcha}</strong>
//                   </p>
//                 </>
//               )}

//               <Button
//                 variant="primary"
//                 type="submit"
//                 className="w-100"
//                 style={{
//                   backgroundColor: isHovered
//                     ? "rgba(255, 255, 255, 0.2)"
//                     : "rgba(255, 255, 255, 0.1)",
//                   border: "1px solid rgba(255, 255, 255, 0.3)",
//                   color: "white",
//                   transition: "background-color 0.3s ease",
//                 }}
//                 onMouseEnter={() => setIsHovered(true)}
//                 onMouseLeave={() => setIsHovered(false)}
//               >
//                 {captcha ? "Verify CAPTCHA" : "Next"}
//               </Button>
//             </Form>

//             <div className="footer text-center my-3">
//               <p style={{ fontSize: "12px", color: "white" }}>
//                 Powered by{" "}
//                 <Link
//                   to="http://globuslabs.com"
//                   style={{ color: "white", textDecoration: "none" }}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   Globus Labs
//                 </Link>
//               </p>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default SignUp;



import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import HeaderNavbar from "../../component/HeaderNavbar";
import { Link, useNavigate } from "react-router-dom";
import "./SignUp.css";
import logo from "../../assets/el_logo.png";
import axios from "axios";

const SignUp = () => {
  const [data, setData] = useState({
    VendorCode: "",
    enteredCaptcha: "",
    utmId: "",
  });

  const [captcha, setCaptcha] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const [vendorData, setVendorData] = useState([]);
  const [userTypeMain, setUserTypeMain] = useState([]);

  useEffect(() => {
    const fetchVendorCode = async () => {
      try {
        const res = await axios.get("/v1/api/vendor/get");
        setVendorData(res.data);
      } catch (error) {
        console.error("Error fetching vendor codes:", error);
      }
    };

    const fetchUserTypeMain = async () => {
      try {
        const res = await axios.get("/v1/api/userTypeMain/get");
        setUserTypeMain(res.data);
      } catch (error) {
        console.error("Error fetching User Type Main:", error);
      }
    };

    fetchVendorCode();
    fetchUserTypeMain();
  }, []);


  useEffect(() => {
    const generateCaptcha = () => {
      const randomCaptcha = Math.floor(1000 + Math.random() * 9000).toString();
      setCaptcha(randomCaptcha);
    };
    generateCaptcha();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCaptchaSubmit = (e) => {
    e.preventDefault();

    if (data.utmId === "UTM003") {
      const userExists = vendorData.some(
        (vendor) => vendor.vendorCode === data.VendorCode
      );
      if (!userExists) {
        alert("Invalid Vendor Code. Please try again.");
        return;
      }
    }

    if (data.enteredCaptcha === captcha) {
      navigate("/SignUp2",{
        state: { vendorCode: data.VendorCode, utmId: data.utmId },
      });
    } else {
      alert("Incorrect CAPTCHA. Please try again.");
    }
  };

  return (
    <div className="SignUp-main">
      <HeaderNavbar />
      <Container
        className="d-flex align-items-center justify-content-end flex-row"
        style={{ width: "100%", height: "calc(100vh - 150px)" }}
      >
        <Row
          className="w-100 py-4 px-2 m-2"
          style={{
            maxWidth: "400px",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            borderRadius: "8px",
          }}
        >
          <Col>
            <div className="text-center mb-4">
              <img src={logo} alt="OWM Logo" style={{ width: "65px" }} />
              <hr style={{ color: "white", margin: "10px 0px" }} />
              <h3 style={{ color: "white" }}>Sign Up</h3>
            </div>

            {/* Form */}
            <Form onSubmit={handleCaptchaSubmit} className="SignUp-form p-2 rounded">
              <Form.Group className="mb-3" controlId="formBasicUserTypeMain">
                <Form.Select
                  name="utmId"
                  value={data.utmId}
                  onChange={handleChange}
                  className="transparent-input"
                  aria-label="Select your User Type"
                >
                  <option value="" disabled>
                    Select Type
                  </option>
                  {userTypeMain.map((type) => (
                    <option key={type.utmId} value={type.utmId}>
                      {type.userTypeMain}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>


              {data.utmId === "UTM003" && (
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter Vendor Code"
                    name="VendorCode"
                    value={data.VendorCode}
                    onChange={handleChange}
                    className="transparent-input"
                    aria-label="Enter your Vendor Code"
                  />
                </Form.Group>
              )}

              {/* CAPTCHA Section */}
              {captcha && (
                <>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Enter CAPTCHA"
                      className="transparent-input"
                      name="enteredCaptcha"
                      value={data.enteredCaptcha}
                      onChange={handleChange}
                      aria-label="Enter CAPTCHA"
                    />
                  </Form.Group>
                  <p
                    style={{
                      color: "black",
                      fontSize: "16px",
                      textAlign: "center",
                      background: "#e7f0fe",
                    }}
                  >
                    CAPTCHA: <strong>{captcha}</strong>
                  </p>
                </>
              )}

              <Button
                variant="primary"
                type="submit"
                className="w-100"
                style={{
                  backgroundColor: isHovered
                    ? "rgba(255, 255, 255, 0.2)"
                    : "rgba(255, 255, 255, 0.1)",
                  border: "1px solid rgba(255, 255, 255, 0.3)",
                  color: "white",
                  transition: "background-color 0.3s ease",
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {/* {captcha ? "Verify CAPTCHA" : "Next"} */}
                Next
              </Button>
            </Form>

            <div className="footer text-center my-3">
              <p style={{ fontSize: "12px", color: "white" }}>
                Powered by{" "}
                <Link
                  to="http://globuslabs.com"
                  style={{ color: "white", textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Globus Labs
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignUp;