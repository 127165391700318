import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { handleApiError } from '../utils/errorHandler';
import { setAlert } from './alertSlice';
// import { setAlert } from './alertSlice';

// Async thunk for fetching status data
export const trackingStatus = createAsyncThunk(
  'trackingStatus/fetchData',
  async (poDoc, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`/v1/api/po/status?poDoc=${poDoc}`);
      return response.data;
    } catch (error) {
      const errorMessage = handleApiError(error);
      dispatch(setAlert({ msg: errorMessage, alertType: 'danger' }));
      return rejectWithValue(errorMessage);
    }
  }
);

// trackingStatus slice
const trackingStatusSlice = createSlice({
  name: 'trackingStatus',
  initialState: {
    trackingStatusData: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetTrackingStatus: (state) => {
      state.loading = false;
      state.error = null;
      state.trackingStatusData = [];
    },


  },
  extraReducers: (builder) => {
    builder
      // Handling fetch cases
      .addCase(trackingStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(trackingStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.trackingStatusData = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(trackingStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});


export const { resetTrackingStatus } = trackingStatusSlice.actions;
export default trackingStatusSlice.reducer;
