import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  Table,
  Pagination,
  Row,
  Col,
} from "react-bootstrap";
import {
  AiOutlineSearch,
  AiOutlineEdit,
  AiOutlineDelete,
} from "react-icons/ai";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import NavbarAdmin from "../../component/NavbarAdmin";
import { fetchMonthlyPoData } from "../../features/monthlyPoSlice";

const columns = [
  { id: "YearMonth", label: "Year Month", minWidth: 150 },
  { id: "poDoc_Of_Asn", label: "PO Doc Of Asn", minWidth: 150 },
  { id: "poDoc_Of_Grn", label: "PO Doc Of Grn", minWidth: 150 },
];

const MonthlyPO = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.monthlyPo); // Fixed the selector

  console.log(data, "data"); // Log the fetched data for debugging

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const rowsPerPage = 100;

  useEffect(() => {
    dispatch(fetchMonthlyPoData({ page, search }));
  }, [dispatch, page, search]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Container fluid className="p-0">
        <NavbarAdmin />
        <Row className="mt-2 mx-2" style={headerStyle}>
          <Col className="d-flex align-items-center">
            <h3 className="mb-0" style={headerTextStyle}>
              Monthly PO
            </h3>
          </Col>
        </Row>
        <Container fluid className="container px-5 py-3 pb-0">
          <Row className="justify-content-start mb-2">
            <Col xs={10} sm={8} md={6} lg={4}>
              <Form className="d-flex">
                <Form.Control
                  type="search"
                  placeholder="Search by Date"
                  className="me-2"
                  value={search}
                  onChange={handleSearchChange}
                  style={{ fontSize: "13px" }}
                />
                <Button
                  variant="outline-warning"
                  style={{ backgroundColor: "#f90", borderColor: "#f90" }}
                  onClick={() => setPage(1)}
                >
                  <AiOutlineSearch style={{ color: "white" }} />
                </Button>
              </Form>
            </Col>
          </Row>

          <div
            style={{
              maxHeight: "calc(100vh - 255px)",
              minHeight: "calc(100vh - 255px)",
              maxWidth: "100%",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              scrollbarWidth: "thin",
              scrollbarColor: "#003c78 transparent",
            }}
          >
            <Table responsive bordered hover>
              <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "700",
                        textAlign: "center",
                        background: "#003c78",
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {data && data.slice().reverse().length > 0 ? (
                  data
                    .slice()
                    .reverse()
                    .slice(0, rowsPerPage)
                    .map((row, index) => (
                      <tr key={index}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <td
                              key={column.id}
                              style={{
                                fontSize: "12px",
                                textAlign: "center",
                                fontWeight: "600",
                              }}
                            >
                              {column.id === "asnUploadDateLT" ||
                              column.id === "uploadedAt" ? (
                                moment(value).format("DD/MM/YYYY")
                              ) : column.id === "actions" ? (
                                <>
                                  <AiOutlineEdit
                                    style={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                      marginRight: 10,
                                      color: "blue",
                                    }}
                                  />
                                  <AiOutlineDelete
                                    style={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                      marginRight: 10,
                                      color: "red",
                                    }}
                                  />
                                </>
                              ) : (
                                value
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td
                      colSpan={columns.length}
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      No data found in the specified condition.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          {loading && <span>Loading...</span>}
          {error && <span style={{ color: "red" }}>{error}</span>}

          <Pagination className="justify-content-end mt-2">
            <Pagination.Prev
              onClick={() => handleChangePage(page > 1 ? page - 1 : 1)}
            />
            <Pagination.Item>{page}</Pagination.Item>
            <Pagination.Next onClick={() => handleChangePage(page + 1)} />
          </Pagination>
        </Container>
      </Container>
    </>
  );
};

export default MonthlyPO;

// Styles
const headerStyle = {
  height: "50px",
  backgroundColor: "#e9ecef",
  borderBottom: "2px solid #ced4da",
  padding: "5px",
};

const headerTextStyle = {
  fontSize: "14px",
  textTransform: "uppercase",
  fontWeight: "600",
  color: "#003c78",
};
