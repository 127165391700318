import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setAlert } from './alertSlice';
import { handleApiError } from '../utils/errorHandler';



// Async thunk for uploading Grn data
export const grnUpload = createAsyncThunk(
  'grn/upload',
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post('/v1/api/grn/upload', formData);
      dispatch(setAlert({ msg: 'File uploaded successfully!', alertType: 'success' }));
      return response.data;
    } catch (error) {
      const errorMessage = handleApiError(error);
      dispatch(setAlert({ msg: errorMessage, alertType: 'danger' }));
      return rejectWithValue(errorMessage);
    }
  }
);

// Grn fetch data
export const fetchGrnData = createAsyncThunk(
  'fetchGrnData/fetchGrn',
  async ({ page, search }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`/v1/api/grn/poDetails?page=${page}&search=${search}`);
      return response.data;
    } catch (error) {
      const errorMessage = handleApiError(error);
      dispatch(setAlert({ msg: errorMessage, alertType: 'danger' }));
      return rejectWithValue(errorMessage);
    }
  }
);

//latest uploaded user details
export const latestUploadedByGrn = createAsyncThunk(
  "grn/latestUploadedByGrn",
  async (_, thunkAPI) => {
    try {
      const response = await fetch("/v1/api/grn/latestUpload");
      if (!response.ok) {
        throw new Error("Failed to fetch latest upload data");
      }

      return await response.json();
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


// grn slice
const grnSlice = createSlice({
  name: 'grnUpload',
  initialState: {
    grnUploadData: [],
    grnFetchData: [],
    latestUploadByGrnData: null,
    loading: {
      upload: false,
      fetch: false,
      latestUpload: false,
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(grnUpload.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(grnUpload.fulfilled, (state, action) => {
        state.loading = false;
        // // Ensure action.payload is an array
        // if (Array.isArray(action.payload)) {
        //   state.grnUploadData.push(...action.payload);
        // } else {
        //   console.error("Payload is not an array:", action.payload);
        // }

        state.grnUploadData = Array.isArray(action.payload) ? [...state.grnUploadData, ...action.payload] : [];

      })
      .addCase(grnUpload.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchGrnData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGrnData.fulfilled, (state, action) => {
        state.loading = false;
        state.grnFetchData = action.payload; // Update with fetched data
        // state.asnFetchData = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(fetchGrnData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(latestUploadedByGrn.pending, (state) => {
        state.loading.latestUpload = true;

      })
      .addCase(latestUploadedByGrn.fulfilled, (state, action) => {
        state.loading.latestUpload = false;
        state.latestUploadByGrnData = action.payload;
        // state.error = null;
      })
      .addCase(latestUploadedByGrn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default grnSlice.reducer;





