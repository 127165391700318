// import React, { useEffect, useState } from "react";
// import { Form, Button, Container, Row, Col } from "react-bootstrap";
// import HeaderNavbar from "../../component/HeaderNavbar";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import "./SignUp.css";
// import logo from "../../assets/el_logo.png";
// import axios from "axios";
// import { useDispatch } from 'react-redux';
// import { signupUser } from '../../features/authSlice';

// const SignUp2 = () => {
//   const dispatch = useDispatch()
//   const location = useLocation();
//   const navigate = useNavigate()
//   const { vendorCode} = location.state || {};
//   const [userdata, setUserData] = useState({
//     email: "",
//     password: "",
//     utmId: 'UTM003',
//     userTypeId: 'UT002',
//     vendorCode: vendorCode || null,
//     fullName: "",
//     phoneNumber: "",
//   });

//   const [isHovered, setIsHovered] = useState(false);



//   console.log(userdata);
  

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setUserData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };


//   // const handleSubmit = (e) => {
//   //   e.preventDefault();

//   //   if (userdata.password !== userdata.ConfirmPassword) {
//   //     alert('Password and Confirm Password do not match!');
//   //     return;
//   //   }
//   //   if (userdata) {
//   //     dispatch(signupUser(userdata));
//   //     setUserData({
//   //       email: "",
//   //       password: "",
//   //       utmId: "",
//   //       userTypeId: "",
//   //       vendorCode: vendorCode,
//   //       userName: "",
//   //       phoneNumber: "",
//   //       ConfirmPassword: ""
//   //     });

//   //     setTimeout(() => {
//   //       navigate("/login");
//   //     }, 5000);

//   //   } else {
//   //     console.log("error to signup ");
//   //   }

//   // };


//   const handleSubmit = async (e) => {
//     e.preventDefault();
  
//     // Validate passwords
//     if (userdata.password !== userdata.ConfirmPassword) {
//       alert('Password and Confirm Password do not match!');
//       return;
//     }
  
//     // Validate required fields
//     const requiredFields = [
//       'email',
//       'password',
//       'utmId',
//       'userTypeId',
//       'userName',
//       'phoneNumber'
//     ];
  
//     for (const field of requiredFields) {
//       if (!userdata[field]) {
//         alert(`Please fill in the ${field} field.`);
//         return;
//       }
//     }
  
//     try {
//       // Dispatch signup action
//       const response = await dispatch(signupUser(userdata)).unwrap();
  
//       if (response.status === 201) {
//         // Reset form
//         setUserData({
//           email: "",
//           password: "",
//           utmId: "",
//           userTypeId: "",
//           vendorCode: vendorCode,
//           fullName: "",
//           phoneNumber: "",
//           ConfirmPassword: ""
//         });

//         setTimeout(() => {
//           navigate("/login");
//         }, 5000);

//       } else {
//         // Handle backend error messages
//         alert(response.message || "An error occurred during signup.");
//       }
//     } catch (error) {
//       alert("Signup failed. Please try again later.");
//     }
//   };
  
//   return (
//     <div className="SignUp-main2">
//       <HeaderNavbar />
//       <Container
//         className="d-flex align-items-center justify-content-end flex-row"
//         style={{ width: "100%", height: "calc(100vh - 150px)" }}
//       >
//         <Row
//           className="w-100 py-4 px-2 m-2"
//           style={{
//             maxWidth: "500px",
//             backgroundColor: "rgba(0, 0, 0, 0.9)",
//             borderRadius: "8px",
//           }}
//         >
//           <Col>
//             <div className="text-center mb-4">
//               <img src={logo} alt="OWM Logo" style={{ width: "65px" }} />
//               <hr style={{ color: "white", margin: "10px 0px" }} />
//               <h3 style={{ color: "white" }}>Sign Up</h3>
//             </div>
//             <Form onSubmit={handleSubmit} className="SignUp-form p-2 rounded">
//               <Row className="mb-2">
//               </Row>
//               <Form.Group className="mb-3" controlId="formBasicUser">
//                 <Form.Control
//                   type="text"
//                   placeholder="Full Name"
//                   name="fullName"
//                   value={userdata.fullName}
//                   onChange={handleChange}
//                   className="transparent-input"
//                   aria-label="Enter your user name"
//                 />
//               </Form.Group>
//               <Row className="mb-3">
//                 <Col>
//                   <Form.Group controlId="formBasicEmail">
//                     <Form.Control
//                       type="email"
//                       placeholder="Email/UserName"
//                       name="email"
//                       value={userdata.email}
//                       onChange={handleChange}
//                       className="transparent-input"
//                       aria-label="Enter your email"
//                     />
//                   </Form.Group>
//                 </Col>
//                 <Col>
//                   <Form.Group controlId="formBasicPhone">
//                     <Form.Control
//                       type="number"
//                       placeholder="Phone Number"
//                       name="phoneNumber"
//                       value={userdata.phoneNumber}
//                       onChange={handleChange}
//                       className="transparent-input"
//                       aria-label="Enter your phone number"
//                     />
//                   </Form.Group>
//                 </Col>
//               </Row>

//               <Row className="mb-3">
//                 <Col>
//                   <Form.Group controlId="formBasicPassword">
//                     <Form.Control
//                       type="password"
//                       placeholder="Password"
//                       name="password"
//                       value={userdata.password}
//                       onChange={handleChange}
//                       className="transparent-input"
//                       aria-label="Enter your password"
//                     />
//                   </Form.Group>
//                 </Col>
//                 <Col>
//                   <Form.Group controlId="formBasicConfirmPassword">
//                     <Form.Control
//                       type="password"
//                       placeholder="Confirm Password"
//                       name="ConfirmPassword"
//                       value={userdata.ConfirmPassword}
//                       onChange={handleChange}
//                       className="transparent-input"
//                       aria-label="Confirm your password"
//                     />
//                   </Form.Group>
//                 </Col>
//               </Row>
//               <Button
//                 variant="primary"
//                 type="submit"
//                 className="w-100"
//                 style={{
//                   backgroundColor: isHovered
//                     ? "rgba(255, 255, 255, 0.2)"
//                     : "rgba(255, 255, 255, 0.1)",
//                   border: "1px solid rgba(255, 255, 255, 0.3)",
//                   color: "white",
//                   transition: "background-color 0.3s ease",
//                 }}
//                 onMouseEnter={() => setIsHovered(true)}
//                 onMouseLeave={() => setIsHovered(false)}
//               >
//                 Sign Up
//               </Button>
//             </Form>

//             <div className="footer text-center my-3">
//               <p style={{ fontSize: "12px", color: "white" }}>
//                 Powered by{" "}
//                 <Link
//                   to="http://globuslabs.com"
//                   style={{ color: "white", textDecoration: "none" }}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   Globus Labs
//                 </Link>
//               </p>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );

// };

// export default SignUp2;

import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import HeaderNavbar from "../../component/HeaderNavbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/el_logo.png";
import { useDispatch } from 'react-redux';
import { signupUser } from '../../features/authSlice';
import './SignUp.css';

const SignUp2 = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { vendorCode } = location.state || {};

  // Initialize user data
  const [userdata, setUserData] = useState({
    email: "",
    password: "",
    utmId: 'UTM003',
    userTypeId: 'UT002',
    vendorCode: vendorCode || null,
    fullName: "",
    phoneNumber: "",
    ConfirmPassword: "",
  });

  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevData => ({ ...prevData, [name]: value }));
  };

  // Validate the form inputs
  const validateForm = () => {
    if (userdata.password !== userdata.ConfirmPassword) {
      alert("Password and Confirm Password do not match!");
      return false;
    }

    const requiredFields = [
      "email", "password", "utmId", "userTypeId", "fullName", "phoneNumber"
    ];

    for (const field of requiredFields) {
      if (!userdata[field]) {
        alert(`Please fill in the ${field} field.`);
        return false;
      }
    }
    return true;
  };

  // Reset form after successful submission or error
  const resetForm = () => {
    setUserData({
      email: "",
      password: "",
      utmId: 'UTM003',
      userTypeId: 'UT002',
      vendorCode: vendorCode || null,
      fullName: "",
      phoneNumber: "",
      ConfirmPassword: ""
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);  // Start loading

    try {
      const res = await dispatch(signupUser(userdata)).unwrap();
      // Check for successful signup
      if (res.status === 201) { 
        console.log("Successfully signed up, redirecting to login...");
        resetForm();  // Reset form
        setTimeout(() => navigate("/login"), 3000);  
      } else {
        alert(res.message || "An error occurred during signup."); 
      }
    } catch (error) {
      console.error("Signup error:", error); // Log the error
      alert("Signup failed. Please try again later.");
      resetForm();
    } finally {
      setLoading(false);  // End loading
    }
  };

  return (
    <div className="SignUp-main2">
      <HeaderNavbar />
      <Container className="d-flex align-items-center justify-content-end flex-row" style={{ width: "100%", height: "calc(100vh - 150px)" }}>
        <Row className="w-100 py-4 px-2 m-2" style={{ maxWidth: "500px", backgroundColor: "rgba(0, 0, 0, 0.9)", borderRadius: "8px" }}>
          <Col>
            <div className="text-center mb-4">
              <img src={logo} alt="OWM Logo" style={{ width: "65px" }} />
              <hr style={{ color: "white", margin: "10px 0px" }} />
              <h3 style={{ color: "white" }}>Sign Up</h3>
            </div>
            <Form onSubmit={handleSubmit} className="SignUp-form p-2 rounded">
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Full Name"
                  name="fullName"
                  value={userdata.fullName}
                  onChange={handleChange}
                  className="transparent-input"
                  aria-label="Enter your full name"
                />
              </Form.Group>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder="Email/UserName"
                      name="email"
                      value={userdata.email}
                      onChange={handleChange}
                      className="transparent-input"
                      aria-label="Enter your email"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formBasicPhone">
                    <Form.Control
                      type="number"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      value={userdata.phoneNumber}
                      onChange={handleChange}
                      className="transparent-input"
                      aria-label="Enter your phone number"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={userdata.password}
                      onChange={handleChange}
                      className="transparent-input"
                      aria-label="Enter your password"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formBasicConfirmPassword">
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                      name="ConfirmPassword"
                      value={userdata.ConfirmPassword}
                      onChange={handleChange}
                      className="transparent-input"
                      aria-label="Confirm your password"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button
                variant="primary"
                type="submit"
                className="w-100"
                style={{
                  backgroundColor: isHovered ? "rgba(255, 255, 255, 0.2)" : "rgba(255, 255, 255, 0.1)",
                  border: "1px solid rgba(255, 255, 255, 0.3)",
                  color: "white",
                  transition: "background-color 0.3s ease",
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                disabled={loading}
              >
                {loading ? 'Signing Up...' : 'Sign Up'}
              </Button>
            </Form>

            <div className="footer text-center my-3">
              <p style={{ fontSize: "12px", color: "white" }}>
                Powered by{" "}
                <Link to="http://globuslabs.com" style={{ color: "white", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                  Globus Labs
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignUp2;





























//for admin use do not delete below code
// import React, { useEffect, useState } from "react";
// import { Form, Button, Container, Row, Col } from "react-bootstrap";
// import HeaderNavbar from "../../component/HeaderNavbar";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import "./SignUp.css";
// import logo from "../../assets/el_logo.png";
// import axios from "axios";
// import { useDispatch } from 'react-redux';
// import { signupUser } from '../../features/authSlice';

// const SignUp2 = () => {
//   const dispatch = useDispatch()
//   const location = useLocation();
//   const navigate = useNavigate()
//   const { vendorCode, utmId} = location.state || {};
//   const [userdata, setUserData] = useState({
//     email: "",
//     password: "",
//     utmId: utmId,
//     userTypeId: 'UT002',
//     vendorCode: vendorCode || null,
//     userName: "",
//     phoneNumber: "",
//   });

//   const [isHovered, setIsHovered] = useState(false);



//   console.log(userdata);
  

  

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setUserData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (userdata.password !== userdata.ConfirmPassword) {
//       alert('Password and Confirm Password do not match!');
//       return;
//     }
//     if (userdata) {
//       dispatch(signupUser(userdata));
//       setUserData({
//         email: "",
//         password: "",
//         utmId: "",
//         userTypeId: "",
//         vendorCode: vendorCode,
//         userName: "",
//         phoneNumber: "",
//         ConfirmPassword: ""
//       });

//       setTimeout(() => {
//         navigate("/login");
//       }, 5000);

//     } else {
//       console.log("error to signup ");

//     }

//   };

//   return (
//     <div className="SignUp-main2">
//       <HeaderNavbar />
//       <Container
//         className="d-flex align-items-center justify-content-end flex-row"
//         style={{ width: "100%", height: "calc(100vh - 150px)" }}
//       >
//         <Row
//           className="w-100 py-4 px-2 m-2"
//           style={{
//             maxWidth: "500px",
//             backgroundColor: "rgba(0, 0, 0, 0.9)",
//             borderRadius: "8px",
//           }}
//         >
//           <Col>
//             <div className="text-center mb-4">
//               <img src={logo} alt="OWM Logo" style={{ width: "65px" }} />
//               <hr style={{ color: "white", margin: "10px 0px" }} />
//               <h3 style={{ color: "white" }}>Sign Up</h3>
//             </div>
//             <Form onSubmit={handleSubmit} className="SignUp-form p-2 rounded">
//               <Row className="mb-2">
//               </Row>
//               <Form.Group className="mb-3" controlId="formBasicUser">
//                 <Form.Control
//                   type="text"
//                   placeholder="User Name"
//                   name="userName"
//                   value={userdata.userName}
//                   onChange={handleChange}
//                   className="transparent-input"
//                   aria-label="Enter your user name"
//                 />
//               </Form.Group>
//               <Row className="mb-3">
//                 <Col>
//                   <Form.Group controlId="formBasicEmail">
//                     <Form.Control
//                       type="email"
//                       placeholder="Email"
//                       name="email"
//                       value={userdata.email}
//                       onChange={handleChange}
//                       className="transparent-input"
//                       aria-label="Enter your email"
//                     />
//                   </Form.Group>
//                 </Col>
//                 <Col>
//                   <Form.Group controlId="formBasicPhone">
//                     <Form.Control
//                       type="number"
//                       placeholder="Phone Number"
//                       name="phoneNumber"
//                       value={userdata.phoneNumber}
//                       onChange={handleChange}
//                       className="transparent-input"
//                       aria-label="Enter your phone number"
//                     />
//                   </Form.Group>
//                 </Col>
//               </Row>

//               <Row className="mb-3">
//                 <Col>
//                   <Form.Group controlId="formBasicPassword">
//                     <Form.Control
//                       type="password"
//                       placeholder="Password"
//                       name="password"
//                       value={userdata.password}
//                       onChange={handleChange}
//                       className="transparent-input"
//                       aria-label="Enter your password"
//                     />
//                   </Form.Group>
//                 </Col>
//                 <Col>
//                   <Form.Group controlId="formBasicConfirmPassword">
//                     <Form.Control
//                       type="password"
//                       placeholder="Confirm Password"
//                       name="ConfirmPassword"
//                       value={userdata.ConfirmPassword}
//                       onChange={handleChange}
//                       className="transparent-input"
//                       aria-label="Confirm your password"
//                     />
//                   </Form.Group>
//                 </Col>
//               </Row>
//               <Button
//                 variant="primary"
//                 type="submit"
//                 className="w-100"
//                 style={{
//                   backgroundColor: isHovered
//                     ? "rgba(255, 255, 255, 0.2)"
//                     : "rgba(255, 255, 255, 0.1)",
//                   border: "1px solid rgba(255, 255, 255, 0.3)",
//                   color: "white",
//                   transition: "background-color 0.3s ease",
//                 }}
//                 onMouseEnter={() => setIsHovered(true)}
//                 onMouseLeave={() => setIsHovered(false)}
//               >
//                 Sign Up
//               </Button>
//             </Form>

//             <div className="footer text-center my-3">
//               <p style={{ fontSize: "12px", color: "white" }}>
//                 Powered by{" "}
//                 <Link
//                   to="http://globuslabs.com"
//                   style={{ color: "white", textDecoration: "none" }}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   Globus Labs
//                 </Link>
//               </p>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );

// };

// export default SignUp2;






