import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import HeaderNavbar from "../../component/HeaderNavbar";
import { Link,  useNavigate  } from "react-router-dom";
import "../Login.css";
import logo from "../../assets/el_logo.png";

const ForgetPassword = () => {
  const [userdata, setUserData] = useState({
    Email: "",
    Password: "",
  });

  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate(); // Hook to handle navigation


  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
   
  };

  return (
    <div className="login-main">
      <HeaderNavbar />
      <Container
        className="d-flex align-items-center justify-content-end flex-row"
        style={{ width: "100%",  height: "calc(100vh - 150px)"}}
      >
        <Row
          className="w-100 py-4 px-2 m-2"
          style={{
            maxWidth: "400px",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            borderRadius: "8px",
          }}
        >
          <Col>
            <div className="text-center mb-4">
              <img src={logo} alt="OWM Logo" style={{ width: "65px" }} />
              <hr style={{ color: "white", margin: "10px 0px" }} />
              <h5 style={{ color: "white" }}>Forget Password</h5>
            </div>
            <Form onSubmit={handleSubmit} className="login-form p-2 rounded">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="Email"
                  value={userdata.Email}
                  onChange={handleChange}
                  className="transparent-input"
                  aria-label="Enter your email"
                />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                className="w-100"
                style={{
                  backgroundColor: isHovered
                    ? "rgba(255, 255, 255, 0.2)"
                    : "rgba(255, 255, 255, 0.1)",
                  border: "1px solid rgba(255, 255, 255, 0.3)",
                  color: "white",
                  transition: "background-color 0.3s ease",
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                Send OTP
              </Button>
            </Form>
            <div className="footer text-center my-3">
              <p style={{ fontSize: "12px", color: "white" }}>
                Powered by{" "}
                <Link
                  to="http://globuslabs.com"
                  style={{ color: "white", textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Globus Labs
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgetPassword