// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';

// import { setAlert } from './alertSlice';


// export const signupUser = createAsyncThunk(
//   'auth/signupUser',
//   async (userdata, { rejectWithValue, dispatch }) => {
//     try {
//       const response = await axios.post('/v1/api/user/add', userdata);
//       dispatch(setAlert({ msg: 'User signed up successfully!', alertType: 'success' }));
//       return { data: response.data, status: response.status };


      
//       // const response = await axios.post('/v1/api/user/add', userdata, {
//       //   headers: { 'Content-Type': 'application/json' },
//       // });

//       // dispatch(setAlert({ msg: 'User signed up successfully!', alertType: 'success' }));
      
//       // return response.data;
      
//     } catch (error) {
//       let errorMsg = 'Signup failed';

//       if (error.response) {
//         // Detailed error handling
//         switch (error.response.status) {
//           case 400:
//             errorMsg = error.response?.data?.message || 'Invalid input. Please check the form fields.';
//             break;
//           case 409:
//             errorMsg = 'User already exists. Please try with a different email or username.';
//             break;
//           case 500:
//             errorMsg = 'Server error. Please try again later.';
//             break;
//           default:
//             errorMsg = error.response?.data?.message || 'An error occurred.';
//         }
//       } else if (error.request) {
//         errorMsg = 'Network error. Please check your internet connection.';
//       } else {
//         errorMsg = `Error: ${error.message}`;
//       }

//       dispatch(setAlert({ msg: errorMsg, alertType: 'danger' }));
//       return rejectWithValue(errorMsg);
//     }
//   }
// );


// // Auth slice
// const authSlice = createSlice({
//   name: 'auth',
//   initialState: {
//     user: null,
//     loading: false,
//     error: null,
//   },
//   reducers: {
//     resetAuthState: (state) => {
//       state.user = null;
//       state.loading = false;
//       state.error = null;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(signupUser.pending, (state) => {
//         state.loading = true;
//         state.error = null;
//       })
//       .addCase(signupUser.fulfilled, (state, action) => {
//         state.loading = false;
//         state.user = action.payload;
//         state.error = null; // Clear error if signup succeeds
//       })
//       .addCase(signupUser.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload; // Store the error message
//       });
//   },
// });

// export const { resetAuthState } = authSlice.actions;

// export default authSlice.reducer;








import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { setAlert } from './alertSlice';


export const signupUser = createAsyncThunk(
  'auth/signupUser',
  async (userdata, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post('/v1/api/user/add', userdata);
      dispatch(setAlert({ msg: 'User signed up successfully!', alertType: 'success' }));
      return { data: response.data, status: response.status };


      
      // const response = await axios.post('/v1/api/user/add', userdata, {
      //   headers: { 'Content-Type': 'application/json' },
      // });

      // dispatch(setAlert({ msg: 'User signed up successfully!', alertType: 'success' }));
      
      // return response.data;
      
    } catch (error) {
      let errorMsg = 'Signup failed';

      if (error.response) {
        // Detailed error handling
        switch (error.response.status) {
          case 400:
            errorMsg = error.response?.data?.message || 'Invalid input. Please check the form fields.';
            break;
          case 409:
            errorMsg = 'User already exists. Please try with a different email or username.';
            break;
          case 500:
            errorMsg = 'Server error. Please try again later.';
            break;
          default:
            errorMsg = error.response?.data?.message || 'An error occurred.';
        }
      } else if (error.request) {
        errorMsg = 'Network error. Please check your internet connection.';
      } else {
        errorMsg = `Error: ${error.message}`;
      }

      dispatch(setAlert({ msg: errorMsg, alertType: 'danger' }));
      return rejectWithValue(errorMsg);
    }
  }
);


export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (userdata, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post('/v1/api/user/login', userdata, {
        withCredentials: true, // If you are handling cookies
      });

      dispatch(setAlert({ msg: 'Login successful!', alertType: 'success' }));

      return { data: response.data, status: response.status };
    } catch (error) {
      let errorMsg = 'Login failed';

      if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMsg = 'Invalid credentials. Please check your username and password.';
            break;
          case 401:
            errorMsg = 'Unauthorized. Please login with valid credentials.';
            break;
          case 500:
            errorMsg = 'Server error. Please try again later.';
            break;
          default:
            errorMsg = error.response?.data?.message || 'An error occurred.';
        }
      } else if (error.request) {
        errorMsg = 'Network error. Please check your internet connection.';
      } else {
        errorMsg = `Error: ${error.message}`;
      }

      dispatch(setAlert({ msg: errorMsg, alertType: 'danger' }));
      return rejectWithValue(errorMsg);
    }
  }
);







// Auth slice
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    loading: false,
    error: null,
    token: null, 
  },
  reducers: {
    resetAuthState: (state) => {
      state.user = null;
      state.loading = false;
      state.error = null;
      state.token = null; 
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null; // Clear error if signup succeeds
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      })

      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data.user;
        state.token = action.payload.data.token; // If you get a token on login
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });
  },
});

export const { resetAuthState } = authSlice.actions;

export default authSlice.reducer;
